.GetistouchContactus{
    width: 100%;
    height: 90vh;
    background-color: #ffffff;
    display: flex;
    overflow: hidden;
    margin-bottom: 6%;
}

.contact-information{
    width: 45%;
    padding-left: 5%;
    background-color :"black";
}
.contact-from{
    width: 50%;
}
.Contact-infoTwo{
    margin-top: 2%;
    margin-bottom: 5%;
    width: 90%;
    height: 100px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.Contact-infoTwo p{
    margin-left: -5%;
    width: 100%;
    margin-top: -20px;

}
.Contact-infoTwo h5{
    margin-top: 5%;
    width: 100%;
    margin-left: -5%;
    font-size: 19px;
}

.GetistouchContactus-two{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    overflow: hidden;
    margin-bottom: 2%;
}
.contact-from h2{
    font-size: 32px;
}


@media only screen and (max-width: 700px) {

    .Contact-infoTwo{
        width: 100%;
    }

    .GetistouchContactus-two{
       flex-direction: column-reverse;
       height: 154vh;
    }
    .contact-information{
width: 90%;
margin-left: 3%;
margin-bottom: 5vh;

    }
    .contact-from{
    
        margin-left: 10%;
        margin-bottom: 10vh;
width: 80%;
    }
    .contact-from h2{
        font-size: 32px;
    }


}
