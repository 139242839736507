.Oursolution{
    width:"100%";
    height: 100%;
    background-color: rgba(197, 255, 252, 0.33);
    margin-bottom: 10vh;
    display: flex;

}
.Oursolution-LeftImage{
    width: 25%;
    height: 100%;
    /* background-color: #fff; */
}
.Oursolution-Center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}
.Oursolution-Center h4{
    text-align: center;
    margin-top: 10%;
    color: rgba(32, 65, 140, 1);
    font-size: 14px;
}
.Oursolution-Center h1{
    margin-top: -1%;
    text-align: center;
    font-size: 46px;
    line-height: 54px;
    color: rgba(59, 66, 75, 1);
}
.Oursolution-Center p{
    color: rgba(121, 126, 132, 1);
    margin: 0 5%;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 82%;
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
}

.Oursolution-RightImage{
    width: 25%;
    height: 100%;
    /* background-color: #fff; */
}
.LeftImageOurSolutions{
    margin-top: 45%;
    margin-left: 20%;
    margin-bottom: 10%;
}
.about-us-svgimage{
    margin-top: 12%;
}
.RightImageOurSolutions{
    margin-top: 15%;
    margin-left: 5%;
}
.whychooseus{
    width: 100%;
    height: 100%;
    padding-bottom: 10vh;
    /* background-color: rgb(208, 208, 208); */
}
.Differentiators{
    width: 90%;
    height: 60%;
    margin: 0 auto;
    /* background-color: #ffffff; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   
}
.Differentiators-Cards{
    display: flex;
    width: 32%;
    height: 220px;
    /* background-color: rgb(222, 222, 222); */
}

.Differentiators-Cards-IconSection{
    width: 30%;
    height: 100%;
    /* background-color: rebeccapurple; */
}
.Differentiators-Cards-IconSection img{
    margin-top: 25%;
    margin-left: 20%;
}
.Differentiators-Cards-ContentSection{
   
    width: 79%;
    height: 100%;
    /* background-color: rgb(93, 182, 255); */
}
.Differentiators-Cards-ContentSection h4{
   font-size: 24px;
   width: 95%;
   color: rgba(59, 66, 75, 1);
}
.Differentiators-Cards-ContentSection p{
    margin-top: -10px;
    color: rgba(121, 126, 132, 1);
    font-size: 15px;
    font-weight: 500;
}


.whychooseus-content h1{
    text-align: center;
    color: rgba(59, 66, 75, 1);
    font-size: 46px;
    font-weight: 700;
}
.whychooseus-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
@media only screen and (max-width: 700px) {
    .Oursolution-Center h4{
        margin-top: 0%;
    }
    .Oursolution-Center h1{
    line-height: 28px;
    }
    .span-point{
        width: 5px !important;
        height: 5px !important;
    }

    .about-us-svgimage{
        margin-top: 0%;
    }

    .Oursolution {
        margin-bottom: 5vh;
        
    }

    .Oursolution-Center p {
        font-size: 14px;
        width: 90%;
        font-weight: 500;
        margin-top: 3vh;
    }
    .Oursolution-LeftImage img {
        margin-top: 5%;
    }

    
    .Oursolution-Center h1{
        font-size: 24px;
        padding: 0 20px;
    }
    .Oursolution{

        flex-direction: column-reverse;
        height: 155vh;

    }
    .Differentiators-Cards-IconSection img{
width: 60%;
    }
    .Oursolution-Center p{
        margin-top: 2vh;
    }
    .Oursolution-Center {
    width: 100%;
    }
    .Oursolution-LeftImage {
      display: flex;
      align-items: none;
      justify-content: center;
width: 100%;
height: 40%;
margin-bottom: 5vh;

    }
    .Differentiators-Cards-ContentSection h4{
font-size: 14px;
    }
    .Differentiators-Cards-ContentSection p{
   font-size: 13.5px;
   

    }
    .Differentiators-Cards{
        margin-left: -8%;
    }


    .Oursolution-LeftImage img{
        width: 50%;
    }
    .Oursolution-RightImage{
     
height: 60%;
        display: flex;
      align-items: center;
      justify-content: center;
        width: 100%;
    }
    .Oursolution-RightImage img{
        width: 50%;
    }
    .LeftImageOurSolutions{
        margin-top: 0%;
        margin-left: 0%;
    }
    .RightImageOurSolutions{
        margin-top: 0%;
        margin-left: 0%;
    }
    .Differentiators{
        justify-content: center;
    }
    .whychooseus{
        height: 150vh;
    }
    .Differentiators-Cards {
        width: 310px;
        height: 145px;
        margin-bottom: 25px;
    }



}