.GetistouchContactus-projectoverview{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    overflow: hidden;
    margin-bottom: 5%;
}
.HaveQuestionHeading{
    font-size: 46px;
    width: 70%;
}

.contact-from-project-overview{
    width: 60%;
}


.Applicationsofproducts{
    margin-top: 5vh;
    width: 100%;
    height: 100%;
    margin-bottom: 10vh;
    /* background-color: rgb(208, 208, 208); */
}
.Applicationsofproducts-firstheading{
    width: 90%;
}

.Applicationsofproducts-Cards{
    /* border-radius: 20px; */
    width: 390px;
    height: 220px;
    /* background-color: rgb(82, 81, 81); */
}
.Applicationsofproducts-Cards h2{
    color: rgba(23, 61, 122, 1);
    /* width: 90%; */
    font-size: 28px;
}
.Applicationsofproducts-Cards p{
    color: rgba(121, 126, 132, 1);
    font-size: 16px;
    width: 96%;
}

.Projectoverviewonemain{
    margin-top: 10vh;
    width: 100%;
    height: 94vh;
    background-color: rgb(255, 255, 255);
}
.ModularServersbanner{
    display: flex;
    width: 100%;
    height: 40%;
    background-color: rgba(32, 65, 140, 1);
}
.ModularServersbanner-left{
    width: 50%;
    height: 100%;
    /* background-color: #ffffff; */
    color: rgb(255, 255, 255);
}
.ModularServersbanner-left h1{
    margin-top: 12%;
    font-size: 42px;

    margin-left: 10%;
}
.ModularServersbanner-left p{
    width: 70%;
    margin-left: 10%;
    line-height: 25px;
    margin-top: -2%;
    font-size: 18px;
    line-height: 30px;
}
.ModularServersbanner-right{
    width: 50%;
    height: 100%;
    /* background-color: #717171; */
}
.ModularServersbanner-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ModularServersbanner-right img{
    width: 95%;
    height: 142%;
    margin-top: 30%;
    margin-right: 10%;
    z-index: 1;
}
.ModularServersbannerTwo{
    width: 100%;
    height: 50%;
    /* background-color: aquamarine; */
    display: flex;
}

.ModularServersbannerTwo-Left{
    width: 50%;
    height: 100%;
    /* background-color: #ffb3b3; */
}
.ModularServersbannerTwo-Left img{
    margin-top: 3%;
    margin-left: 9%;
    width: 80%;
}
.ModularServersbannerTwo-Right{
    width: 50%;
    height: 100%;
    /* background-color: #797979; */
}
.ModularServersbannerTwo-Right p{
    margin-top: 28%;
    font-size: 18px;
    line-height: 30px;
    color: rgba(59, 66, 75, 1);
    width: 80%;
    font-weight: 500;
    
}

.Projectoverviewspecifications{
    width: 100%;
    height: 100vh;
    /* background-color: cadetblue; */
    display: flex
}
.Projectoverviewspecifications-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100vh;
    
}
.Specifications-heading{
    text-align: center !important;
    margin-top: 15% !important;
}

.Projectoverviewspecifications-leftCard{
    width: 70%;
    height: 45%;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   background-color: #ffffff;
   border-radius: 15px;

   margin-top: 10%;
}

.Projectoverviewspecifications-rightCard{
    width: 70%;
    height: 45%;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   background-color: #ffffff;
   border-radius: 15px;

   margin-top: 10%;
}
.Projectoverviewspecifications-center{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: -3%;
    width: 40%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
}
.Overview-center-heading{
    color: rgba(59, 66, 75, 1);
    font-size: 46px;
    
   
}
.Projectoverviewspecifications-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100vh;
    
}


.Projectoverviewspecifications-MainCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 75%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
}
.Projectoverviewspecifications-MainCard h2{
  color: rgba(59, 66, 75, 1);
  margin-top: 15%;
  font-size: 36px;
}
.contact-information-projectoverview{
    width: 45%;
    padding-left: 5%;
    background-color :"black";
}

@media only screen and (max-width: 1400px) {
    .Specifications-heading {
        margin-top: 8% !important;
        margin-bottom: -2% !important;
    }
    .unordered-list-card li{
        font-size: 13px !important;
        width: 90%;

    }

    .Overview-center-heading{
        font-size: 32px;
    }
    .HaveQuestionHeading{
        font-size: 25px;
    }

.Applicationsofproducts-Cards{
    /* border-radius: 20px; */
    width: 320px;
    height: 220px;
    /* background-color: rgb(82, 81, 81); */
}

}
.unordered-list-card{
    margin-left: 5%;
}
.unordered-list-card li{
    font-size: 15px;
    font-weight: 500;
    font-family: "Urbanist", sans-serif;
    color: rgba(121, 126, 132, 1);
}


@media only screen and (max-width: 700px) {

    .Projectoverviewspecifications-MainCard h2 {
        font-size: 24px;
    }

    .contact-from-project-overview h2{
        width: 90%;
    }

    .Projectoverviewspecifications-MainCard {
        width: 80%;
        height: 46vh;
    }


    .Projectoverviewspecifications-rightCard{
        width: 80%;
    }

    .Projectoverviewspecifications-leftCard{
width: 80%;
    }

    .Projectoverviewspecifications-left {
    

width: 100%;
    }
    .Projectoverviewspecifications-right {
width: 100%;
    }
    .Projectoverviewspecifications-center{
        order: 1;
        width: 100%;
    }

    .Projectoverviewspecifications{
        flex-direction: column-reverse;
        height: 195vh;
    }
    .contact-information-projectoverview{
        width: 96%;
        margin-left: 5%;
        margin-bottom: 5vh;
        
            }
    .whychooseus-content h1{
        font-size: 24px;
    }
    .Applicationsofproducts-firstheading{
        width: 90% !important;
    }
    .Applicationsofproducts-Cards h2{
        font-size: 21px;
        width: 99%;

    }
    .contact-from-project-overview{
        margin-left: 10%;
        width: 90%;
    }
    .Applicationsofproducts{
        height: 120vh;
        margin-top: 5vh;
    }

    .ModularServersbanner-right img {
        margin-right: 0%;
        width: 80%;
    }
    .ModularServersbannerTwo {
        height: 60%;
    }
    .ModularServersbannerTwo-Right p {
        margin-top: 30%;
margin-left: 10%;
width: 80%;
font-size: 15px;
line-height: 22px;
    }


.ModularServersbanner-left h1{
    font-size: 26px
}

.ModularServersbanner-left p{
    font-size: 15px;
    width: 80%;
    line-height: 22px;

}
    .Projectoverviewonemain{
        height: 120vh;
        overflow: hidden;
    }
    .ModularServersbannerTwo-Right{
        width: 100%;
        height: 40%;
    }
    .ModularServersbanner{
        flex-direction: column;
        height: 35%;
    }
    .ModularServersbanner-left{
        width: 100%;
       
    }
    .ModularServersbanner-right{
        width: 100%;
        height: 50%;
    }
    .ModularServersbannerTwo{
        flex-direction: column-reverse;
    }
    .ModularServersbannerTwo-Left img{
        width: 80%;
    }
    .GetistouchContactus-projectoverview{
       flex-direction: column;
       height: 145vh;
       margin-bottom: 0%;
    }
    .ModularServersbannerTwo-Left{
        width: 100%;
    }

    .Applicationsofproducts-Cards{
        width: 290px;
        height: 130px;
        margin-bottom: 15px;
    }
    .Applicationsofproducts-Cards p {
       width: 100%;
       font-size: 14px;
    }

.comboimageWidth{
    width: 82%;
    margin-top: 5%;
}

}