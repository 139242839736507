.Preloader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    transition: opacity 0.75s, visibility 0.7s;
}
.AnimJsonSection{
    width: 20%;
}

@media only screen and (max-width: 700px) {

.AnimJsonSection{
   width: 50%;
}
}