.banner{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: white;
    margin-top: 9vh;
   
}
.BgBannerMobileImage{
    display: none;
}


.banner-content{
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: #ffff; */
}
.banner-content-left{
    color: rgba(255, 255, 255, 1);
    padding: 10vh 0vh 10vh 10vh;
    width: 55%;
    height: 100%;
    /* background-color: chartreuse; */
}
.banner-content-left h1{
    font-size: 46px;
    width: 90%;
    font-weight: 700;
    line-height: 60px;
    font-family: Urbanist, sans-serif;

}
.banner-content-left p{
    font-size: 16px;
    font-family: Urbanist, sans-serif;
    font-weight: 500;
    width: 75%;
    /* background-color: white; */
}

.banner-content-right{
    display: flex;
    justify-content: center;
    padding-top: 3vh;
    padding-right: 10vh;
    align-items: center;
    width: 50%;
    height: 100%;
    /* background-color: rgb(85, 244, 255); */
}


@media only screen and (max-width: 700px) {
   .banner{
    width: 100%;
    height: 90vh;
    
   }

    .BgBannerWebImage{
        display: none;
    }
    .BgBannerMobileImage{
        display: initial;
        
    }
    .banner-content{
        flex-direction: column;
    }
    .banner-content-left {
    padding: 0;
     width: 100%;
     height: 50%;
    }
    .banner-content-left h1 {
        width: 80%;
        font-size: 24px;
        font-weight: 700;
        margin-left: 5vh;
        line-height: 30px;
        margin-top: 7vh;
    }
    .banner-content-left p {
        margin-left: 5vh;

        font-size: 13px;
        font-weight: 500;
        margin-top: 3vh;
        margin-bottom: 3vh;
        width: 83%;
        line-height: 20px;
    }
    .banner-content-right{
      padding-top: 0vh;
      padding-right: 0vh;
      width: 100%;
    }


  }