.Navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    background-color: rgba(32, 65, 140, 1);
    position: fixed;
    z-index: 12;
    box-shadow: rgba(0, 0, 0, 0.116) 0px 8px 24px;
    top: 0;
   
  }
  .Navbartwo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 89px;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 11;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 0;
    transition: all 1s ease-in; /* Add this line */

  }
  .logo-class{
    margin-left: 5%;
    /* background-color: #ffff; */
  }
  .logo-class2{
    margin-left: 5%;

  }
  

.nav-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2%;
    width: 400px;
    /* background-color: #ffff; */
    height: 30px;
    color: white;
}
.nav-links p{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.Navmenu{
  display: none;
}
.NavmenuTwo{
  display: none;
}

@media only screen and (max-width: 700px) {
  .NavbarTwo-Link{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 10%;
    
    height: 25%;
    width: 90%;
    border-bottom: 2px solid rgba(242, 243, 245, 1);
    
  }
  .Contactus{
    border:none;
  }
  .NavbarTwo-Link p{
    font-size: 16px;
    font-weight: 500;
    color: rgba(23, 61, 122, 1);
    font-family: "Urbanist", sans-serif;
  }
  .AboutUs{
    margin-top: 5.5vh;
    margin-bottom: 1vh;
  }
  .NavbarTwo-Link img{
    margin-right: 10vw;
  }
  .NavbarTwoLinks{
    position: fixed;
    z-index: 10;
    top: 8vh;
    width: 100%;
    height: 32vh;
    background-color: white;
    transition: all 0.5s ease-in; /* Add this line */
  }

  .Navmenu{
    display: initial;
    margin-left: 50vw;
  }
  .NavmenuTwo{
    display: initial;
    margin-left: 40vw;
  }
.Navbar{

}
.logo-class{
  margin-left: 10%;
  /* background-color: #ffff; */
}
.logo-class2{
  margin-left: 10%;
}
.logo-class2 img{
  width: 80%;
}
.logo-class img{
  width: 105%;
}
.links{
  display: none;
}

.nav-overlay{
  height: 60vh;
  margin-top: 2%;
  background-color: rgba(0, 0, 0, 0.128);
}
}