.Homeaboutuscardsection{
    width: 90%;
    height: 90%;
    margin-left: 10%;
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    
}
.CardOneAboutus{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    width: 230px;
    height: 220px;

    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    margin-bottom: 8vh;
    position: relative;
    box-shadow: rgba(149, 157, 100, 0.2) 0px 8px 16px;
}

.CardOneAboutus h1{
    color: rgba(32, 65, 140, 1);
    font-size: 42px;
}
.CardOneAboutus p{
    color: rgba(59, 66, 75, 1);
    margin-top: -25px;
    font-size: 16px;
}
.CardOneAboutus::before{
    position: absolute;
    content: "";
    border-radius: 20px;
    width: 230px;
    height: 220px;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    z-index: -1;
    background-color: rgba(10, 200, 188, 1);
    transition:  0.5s ;

}   

.CardOneAboutus:hover::before{
    transform: rotate(15deg);
}










@media only screen and (max-width: 700px) {

    .CardOneAboutus{
        width: 130px;
        height: 130px;
        margin-bottom: 4vh;
    }
    .CardOneAboutus::before{
       
        width: 130px;
        height: 130px;
       
    
    }
    .CardOneAboutus h1{
        font-size: 29px;
        margin-bottom: 25px;
    }
    .CardOneAboutus p{
        font-size: 12px;
        font-weight: 500;
        
    }


}