body {
  margin: 0;
  font-family: "Urbanist", sans-serif;

 
}

.active{
  color: rgba(27, 220, 209, 1) !important
}
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@media only screen and (max-width: 700px) {
.about-image-height{
  height: 250px;
}


}
