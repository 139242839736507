.Footer{
    width: 100%;
    background-color: rgba(245, 248, 255, 1);
    display: flex;
    justify-content: center;
}
.Note-Content{
    width: 85%;
    height: 70px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10%;
    padding: 0% 0% 4% 4%;
}
.Note-Content p{
    color: rgba(10, 200, 188, 1) !important;
    width: 80% !important;
    font-size: 16px !important;
    font-weight: 600 !important;

}

.footer-content{
    display: flex;
    flex-direction: column;
    /* padding: 1vh; */
    /* padding: 0vh 10vh; */
  width: 35%;
  /* background-color: rgb(186, 245, 247);   */
  margin-left: 5%;
  /* height: 70%; */
}
.footer-content img{
    margin-top: 5vh;
}
.footer-content p{
    /* width: 105%; */
    margin-top: 2vh;
    color: rgba(121, 126, 132, 1);
    font-size: 16px;
    font-weight: 500;
    width: 80%;
    line-height: 25px;
    font-family: "Urbanist", sans-serif;

}
.quick-links{
    width: 30%;
    /* background-color: rgb(22, 104, 107);   */
    height: 100%;
}
.quick-links h3{
    margin-top:8vh;
    color: rgba(59, 66, 75, 1);
    font-size: 24px;
    font-weight: 700;
}
.quick-links p{
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(10, 200, 188, 1);

}
.help{
    
    width: 40%;
    /* background-color: rgb(65, 140, 143);   */
    height: 100%;
}
.help h3{
    margin-top: 8vh;
    color: rgba(59, 66, 75, 1);
    font-size: 24px;
    font-weight: 700;
}
.help p{
    color: rgba(10, 200, 188, 1);
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
}
.footer-contact{
    width: 30%;
    /* background-color: cadetblue;   */
    height: 100%;
}
.footer-contact h3{
    margin-top: 8vh;
    color: rgba(59, 66, 75, 1);
    font-size: 24px;
    font-weight: 700;
}
.Contact-info{
    display: flex;
    align-items: center;
    margin-top: -16px !important;
    /* cursor: pointer; */
}
.Contact-info p{
    margin-left: 12px;
    width: 75%;
    font-weight: 500;
    font-size: 16px;
    color: rgba(59, 66, 75, 1);
}
.quicklinks-help{
    width: 30%;
    display: flex;
    justify-content: space-between;
margin-right: 5%;
}


@media only screen and (max-width: 700px) {

    .quick-links h3,.help h3,.footer-contact h3 {
        margin-top: 4vh;
    }

.Footer{
    
flex-direction: column;
width: 100%;
height: 120vh;

}
.footer-content{
    margin-left: 10%;
    

}
.footer-content p{
    font-size: 14px;
    line-height: 20px;
   width: 100%;

}
.footer-content {
    /* padding-left: 5vh; */
    height: 50%;

width: 80%;

}
.footer-content img{
    width: 40%;
}
.quicklinks-help {
    /* padding-left: 5vh; */
    margin-left: 10%;
width: 80%;
}

.footer-contact{
    /* padding-left: 5vh; */
    margin-left: 10%;
    width: 80%;
}
.Note-Content p{
    font-size: 14px !important;
    width: 95% !important;
    margin-top: 1vh !important;
    line-height: 20px !important;
    margin-left: 1vh !important;
   
}
.Note-Content{
    width: 99% !important;
    padding: 1%;
}
.quick-links {
    width: 50%;
}


}