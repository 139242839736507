.Madeinindia{
    width: 100%;
    height: 65vh;
    background-color: rgba(197, 255, 252, 0.33);
    margin-bottom: 5vh;
    display: flex;
}

.MiiLeftimage{
  width: 30%;
  height: 100%;
  /* background-color: white; */
}
.MiiCenterimage{
    width: 40%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    /* background-color: rgb(88, 85, 85); */
}
.MiiCenterimage img{
    margin-top: 10%;
}
.MiiCenterimage h3{
    margin-top: -5px;

    font-size: 46px;
    color: rgba(59, 66, 75, 1);
}
.MiiCenterimage p{
    margin-top: -30px;
   font-size: 16px;
   font-weight: 500;
   font-family: Urbanist, sans-serif;
   color: rgba(121, 126, 132, 1);
    text-align: center;
    line-height: 22px;
    width: 95%;
}
.Miirightimage{
   
    width: 30%;
    height: 100%;
    /* background-color: white; */
}

.MiileftAngleimage{
    position: relative;
}

.MiirightAngleimage{
    /* background-color: aqua; */
    position: relative;
   
}
.MiirightAngleimage img{
    position: absolute;
    right: 0;
    width: 80%;
    margin-top: 10vh;

}
.MiileftAngleimage img{
    position: absolute;
    width: 80%;
    left: 0;
    margin-top: 10vh;
}
.miileftbox img{
    z-index: 9;
    width: 67%;
    margin-left: 10vh;
    margin-top: 13vh;
}
.miirightbox img{
    z-index: 9;
    width: 67%;
    margin-right: 10vh;
    margin-top: 13vh;

}


@media only screen and (max-width: 700px) {
    .MiiCenterimage h3{
        font-size: 32px;
        margin-bottom: 15%;
    }
    .MiiCenterimage p{
        font-size: 14px;
    }

.Madeinindia{
    flex-direction: column;
    height: 130vh;
}
.MiiLeftimage{
    height: 80%;
    width: 100%;
}
.MiiCenterimage{
    width: 100%;
}
.Miirightimage{
    width: 100%;
    height: 80%;
}
.MiirightAngleimage img {
    margin-top: 5vh;
}

.MiiCenterimage img{
    width: 40%;
}
.MiileftAngleimage img{
    width: 80%;
}
.miileftbox img{
    margin-left: 8vh;
    width: 70%;
    margin-top: 12vh;
}

.MiirightAngleimage img {
width: 80%;
}
.miirightbox img {
    margin-right: 8vh;
    width: 70%;
    margin-top: 8vh 
}



}
